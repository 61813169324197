import PropTypes from 'prop-types';
import AccountShortcutsDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/AccountShortcutsDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const AccountShortcutsModule = ({ isGrid = false, ...rest }) => {
    const [css] = useStyletron();

    return (
        <section className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)' })}>
            <AccountShortcutsDataTransformerBlock {...rest} />
        </section>
    );
};

AccountShortcutsModule.propTypes = {
    isGrid: PropTypes.bool,
};

export default AccountShortcutsModule;

/**
 * Builds a button theme
 */

const themes: { [key: string]: string } = {
    beige: 'default',
    black: 'inverted',
};

export default (theme = 'beige'): string => {
    if (!theme) {
        return 'default';
    }

    return themes[theme];
};

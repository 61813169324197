import PropTypes from 'prop-types';
import { transformIconButtons } from '@activebrands/core-web/libs/storyblok/utils/transform-icon-button';
import AccountShortcuts from 'components/content-components/AccountShortcuts';

const AccountShortcutsDataTransformerBlock = ({ content = {} }) => {
    const { linkButton } = content;
    const transformedButtons = linkButton ? transformIconButtons(linkButton) : {};

    return <AccountShortcuts shortcuts={transformedButtons} />;
};

AccountShortcutsDataTransformerBlock.propTypes = {
    content: PropTypes.object,
};

export default AccountShortcutsDataTransformerBlock;

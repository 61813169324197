// Disabled eslint because some of the raw data from the Storyblok contains camelcases
/* eslint-disable camelcase */

import { IconButtonData, RawIconButtonData } from '@activebrands/core-web/libs/storyblok/storyblok';
import transformButtonTheme from './transform-button-theme';

export const transformIconButton = (button: RawIconButtonData): IconButtonData => ({
    label: button.label,
    theme: transformButtonTheme(button.theme),
    url: button.link?.url || button.link?.cached_url,
    icon: button?.icon[0]?.icon?.filename,
    rel: button.follow === 'no_follow' ? 'nofollow' : undefined,
});

/**
 * Accepts array of raw icon button data from Storyblok
 */
export const transformIconButtons = (buttons: RawIconButtonData[]): IconButtonData[] =>
    buttons?.map(transformIconButton);
